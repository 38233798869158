/* eslint-disable react/no-danger */
import React, { useContext, useRef, useState } from 'react';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { IoLogoTiktok } from '@react-icons/all-files/io5/IoLogoTiktok';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import { FaClock } from '@react-icons/all-files/fa/FaClock';
import PropTypes from 'prop-types';
import { translate, imagePrettyUrl, getAltText, textWordsLimit } from '~helpers/getters';
import styles from './author-box.module.scss';
import LazyImage from '~hooks/lazy-image';
import Link from '~hooks/link';
import { formatDate } from '~helpers/date-time';
import { Context } from '~context/MainProvider';

export default function AuthorBox({
  author,
  width = 180,
  height = 180,
  email = '../../../images/email.svg',
  showReadMore = true,
  socialIcons = {
    facebook: <FaFacebookF title="Facebook Icon" />,
    instagram: <FaInstagram title="Instagram Icon" />,
    linkedin: <FaLinkedin title="Linkedin Icon" />,
    twitter: (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 13 12"
        fill="currentcolor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.97574 0H11.9181L7.67469 4.84989L12.6667 11.4495H8.75802L5.69659 7.44685L2.19363 11.4495H0.250147L4.78881 6.26203L0 0H4.0079L6.7752 3.65856L9.97574 0ZM9.29408 10.287H10.3703L3.42314 1.1015H2.26822L9.29408 10.287Z" />
      </svg>
    ),
    tiktok: <IoLogoTiktok title="Tiktok Icon" />,
  },
}) {
  const { translations } = useContext(Context) || {};
  const socialLinks = [
    { id: 'fb', link: author?.facebook_profile, icon: socialIcons?.facebook },
    { id: 'ig', link: author?.instagram_profile, icon: socialIcons?.instagram },
    { id: 'li', link: author?.linkedin_profile, icon: socialIcons?.linkedin },
    { id: 'tw', link: author?.twitter_profile, icon: socialIcons?.twitter },
    { id: 'tt', link: author?.tik_tok, icon: socialIcons?.tiktok },
  ];
  const biographyRef = useRef(null);
  const [isReadMore, setIsReadMore] = useState(showReadMore);

  const toggleReadMore = () => {
    biographyRef.current.classList.add(styles.open);
    setIsReadMore(false);
  };

  return (
    <div className={styles.container || ''} id="authorbox">
      <div className={styles.leftContainer || ''}>
        {author?.image_object && (
          <LazyImage
            src={imagePrettyUrl(author?.image_object.filename, width, height)}
            alt={getAltText(author?.image_object, author?.image_alt || author?.name)}
            width={width}
            height={height}
          />
        )}
        <div className={styles.information || ''}>
          <span className={styles.name}>{author?.name}</span>
          {author?.author_title && <p className={styles.title || ''}>{author?.author_title}</p>}
          {socialLinks.length > 0 && (
            <p className={styles.socialIcons || ''}>
              {socialLinks
                .filter((socialLink) => socialLink.link)
                .map((filteredLinks) => (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <a
                    href={filteredLinks.link}
                    className="author-gtm"
                    target="_blank"
                    rel="nofollow noreferrer"
                    key={`author-box-link-${filteredLinks.id}`}
                    aria-label={`${filteredLinks.id} Link`}
                  >
                    {filteredLinks.icon}
                  </a>
                ))}
              {author?.email_address && (
                <a
                  href={`mailto:${author?.email_address}`}
                  className="author-gtm"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <img className={styles.clock || ''} src={email} alt="email" />
                </a>
              )}
            </p>
          )}
        </div>
      </div>
      <div className={styles.bioContainer || ''}>
        <div
          ref={biographyRef}
          className={`${styles.biography} ${
            author?.biography?.split(' ').length > 30 && showReadMore ? styles.showReadMore : ''
          }`}
          role="button"
          onClick={toggleReadMore}
          onKeyDown={toggleReadMore}
          aria-label="continue reading"
          tabIndex={0}
          dangerouslySetInnerHTML={{
            __html:
              author?.biography?.split(' ').length > 45 && isReadMore
                ? `${textWordsLimit(author?.biography, 45)} <span class='${
                    styles.contReadText
                  }'>${translate(translations, 'cont_read', 'continue reading')}</span>`
                : author?.biography,
          }}
        />
      </div>
      {author?.ribbon_label && <div className={styles.ribbon || ''}>{author?.ribbon_label}</div>}
    </div>
  );
}

AuthorBox.propTypes = {
  author: PropTypes.shape({
    image_object: PropTypes.shape({
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      filename: PropTypes.string,
      alt: PropTypes.string,
    }),
    image_alt: PropTypes.string,
    name: PropTypes.string,
    author_title: PropTypes.string,
    email_address: PropTypes.string,
    personal_website: PropTypes.string,
    additional_url: PropTypes.string,
    facebook_profile: PropTypes.string,
    instagram_profile: PropTypes.string,
    linkedin_profile: PropTypes.string,
    twitter_profile: PropTypes.string,
    tik_tok: PropTypes.string,
    biography: PropTypes.string,
    profile_page_path: PropTypes.string,
    ribbon_label: PropTypes.string,
    expertise: PropTypes.shape([]),
  }),
  translations: PropTypes.shape({
    read_more: PropTypes.string,
  }),
  width: PropTypes.number,
  height: PropTypes.number,
  email: PropTypes.string,
  date: PropTypes.string,
  dateFormat: PropTypes.string,
  dateSeparator: PropTypes.string,
  readingTime: PropTypes.string,
  reviewer: PropTypes.shape({
    profile_page_path: PropTypes.string,
    name: PropTypes.string,
  }),
  check: PropTypes.string,
  verifiedIcon: PropTypes.string,
  verifiedIconWidth: PropTypes.number,
  verifiedIconHeight: PropTypes.number,
  readTimeIcon: PropTypes.node,
  contReadIcon: PropTypes.node,
  showReadMore: PropTypes.bool,
  socialIcons: PropTypes.shape({}),
};
